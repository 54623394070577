import React, {useState} from 'react';
import {ApiClient} from "../../lib/ApiClient";
import {InvoiceDataElementComponent} from "./InvoiceDataElementComponent";
import {AppState} from "../../lib/AppState";
import {LoadingComponent} from "../loading/LoadingComponent";


export interface InvoiceDataForm {
    company_name
    street
    street_number
    flat_number
    city
    country
    postal_code
    nip
    email
    note


}

export interface InvoiceDataComponentProps {
    appState: AppState


}

export interface InvoiceDataComponentState {
    forceReload: boolean,
    countries: any
}
//
// const COUNTRY_OPTIONS = `Poland\t(PL)\tBelgium\t(BE)\tGreece\t(EL)\tLithuania\t(LT)\tPortugal\t(PT)
// Bulgaria\t(BG)\tSpain\t(ES)\tLuxembourg\t(LU)\tRomania\t(RO)
// Czechia\t(CZ)\tFrance\t(FR)\tHungary\t(HU)\tSlovenia\t(SI)
// Denmark\t(DK)\tCroatia\t(HR)\tMalta\t(MT)\tSlovakia\t(SK)
// Germany\t(DE)\tItaly\t(IT)\tNetherlands\t(NL)\tFinland\t(FI)
// Estonia\t(EE)\tCyprus\t(CY)\tAustria\t(AT)\tSweden\t(SE)
// Ireland\t(IE)\tLatvia\t(LV`.split(")").map(row => {
//     return {
//         name: row + ")",
//         value: row.trim().replace(/\t/, "").replace(/^(.*)\(/, "").replace(/\)/, "")
//     }
// })
//
// const EU_COUNTRIES = ['PL', 'BE', 'EL', 'LT', 'PT', 'BG', 'ES', 'LU', 'RO', 'CZ', 'FR', 'HU', 'SI', 'DK', 'HR', 'MT', 'SK', 'DE', 'IT', 'NL', 'FI', 'EE', 'CY', 'AT', 'SE', 'IE', 'LV'];



// const COUNTRY_OPTIONS = [
//     {name: "Poland",value: "PL"},
//     {name: "Germany",value: "DE"},
//     {name: "Spain",value: "ES"},
//     {name: "France",value: "FR"},
//     {name: "Portugal",value: "PT"},
//     {name: "Czech Republic",value: "CZ"},
//     {name: "Slovakia",value: "SK"},
//     {name: "Hungary",value: "HU"},
//     {name: "Romania",value: "RO"},
//
// ];

export class InvoiceDataComponent extends React.Component<InvoiceDataComponentProps, InvoiceDataComponentState> {


    componentDidMount() {
        (async () => {
            const countries: any = await this.props.appState.client.request("getCountries");
            // const top = ["US","GB"];
            // countries.forEach(el => {
            //    if (el.european_union) {
            //        top.push(el.alpha_2);
            //    }
            // });
            countries.forEach(el => {
                el.name =  el.english_name + " ["+el.alpha_2+"]";
                el.value = el.alpha_2;
                // el.priority = 100-top.indexOf(el.alpha_2)
            });
            countries.sort((a,b) => {
                // if (a.priority > b.priority) {
                //     return 1;
                // } else if (a.priority < b.priority) {
                //     return -1;
                // } else {
                    return a.english_name.localeCompare(b.english_name);
                // }
            })
            this.setState({countries: countries});
        })();
    }

    vatCheck() {
        const eu = this.state.countries.filter(el => el.european_union).map(el => el.alpha_2);
        if (-1 !== eu.indexOf(this.props.appState.user.invoiceData.country)) {
            // if ("PL" == this.props.appState.user.invoiceData.country) {
            //     return;
            // }
            if (null != this.props.appState.user.invoiceData.nip
                && !/^([A-Z]{2})/.test(this.props.appState.user.invoiceData.nip)
                // && this.props.appState.user.invoiceData.nip.substr(0,2).toLowerCase() !== this.props.appState.user.invoiceData.country.toLowerCase()
            ) {
                this.props.appState.user.invoiceData.nip = this.props.appState.user.invoiceData.country + this.props.appState.user.invoiceData.nip;
                this.setState({forceReload: true});
                setTimeout(() => {
                    this.setState({forceReload: false});
                }, 1);
            }
        }
    }

    save(e) {
        e.preventDefault();
        this.vatCheck();
        (async () => {
            // this.props.appState.setLoading(true);
            console.log("form", this.props.appState.user.invoiceData);
            const saved: any = await this.props.appState.client.request("saveUserData", {form: this.props.appState.user.invoiceData});
            // this.props.appState.setLoading(false);
            console.log("saved", saved);
            if ("ok" !== saved["status"]) {
                this.props.appState.error("An error occured with your invoice data, please check if they are correct");
            } else {
                this.props.appState.success("Saved!");

            }
        })();

    }

    render() {

        if (null == this.state || undefined == this.state.countries || this.state.forceReload) {
            return <LoadingComponent/>;
        }
        return <section>
            <form onSubmit={e => this.save(e)}>
            <InvoiceDataElementComponent form={this.props.appState.user.invoiceData} name={"country"} title={"Country"}
                                         options={this.state.countries}/>
            <InvoiceDataElementComponent form={this.props.appState.user.invoiceData} name={"company_name"}
                                         required={true}
                                         title={"Company / name"}/>
            <InvoiceDataElementComponent form={this.props.appState.user.invoiceData} name={"nip"} title={"Tax ID"}/>
            <InvoiceDataElementComponent form={this.props.appState.user.invoiceData} name={"street"} title={"Street"}
                                        required={true}
            />
            <InvoiceDataElementComponent form={this.props.appState.user.invoiceData} name={"street_number"}

                                         title={"House number"}/>
            <InvoiceDataElementComponent form={this.props.appState.user.invoiceData} name={"flat_number"}
                                         title={"Flat number"}/>
            <InvoiceDataElementComponent form={this.props.appState.user.invoiceData} name={"city"} title={"City"}
                                         required={true}/>
            <InvoiceDataElementComponent form={this.props.appState.user.invoiceData} name={"postal_code"}
                                         required={true}
                                         title={"Postal"}/>

            <section>

                <button type="submit" className="btn btn-primary">Save</button>
            </section>

            </form>
        </section>

    }


}
