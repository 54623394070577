export class ApiClient {
    constructor(private _token) {
    }

    private getEndpoint() {
        if (/localhost/.test(window.location.host)) {
            return "http://localhost:3033/";
        } else {
            return "https://api.pay.yagular.com/";
        }
    }

    public async request(method, data = null) {

        const url = this.getEndpoint() + method;
        console.log("request url", url)
        const options = {
            headers: {
                "Content-Type": "application/json",
                "token": this._token
            }
        };
        if (null != data) {
            options["method"] = "POST";
            options["body"] = JSON.stringify(data);
        }
        let resp = await fetch(url, options);
        resp = await resp.json();

        return resp;
    }
}