import React, {useState} from 'react';
import {AppState} from "../../lib/AppState";




export interface Notification {
    type: string,
    text: string
}

export interface NotificationComponentProps {
    appState: AppState

}
export interface NotificationComponentState {
    notifications: Notification[]
}

export class NotificationComponent extends React.Component<NotificationComponentProps,NotificationComponentState> {


    private addNotification(msg,type) {
        const notification: Notification = {
            "text": msg,
            "type": type
        };
        this.state.notifications.push(notification);
        this.setState({notifications: this.state.notifications});
        setTimeout(() => {
            this.state.notifications.splice(this.state.notifications.indexOf(notification));
            this.setState({notifications: this.state.notifications});
        },2000)
    }

    componentDidMount() {
        this.setState({notifications: []});
        this.props.appState.error = msg => this.addNotification(msg,"danger");
        this.props.appState.success = msg => this.addNotification(msg,"success");
    }


    render() {
        if (null == this.state || 0 == this.state.notifications.length) {
            return <div/>;
        }
        return <div className="app-notifications text-center">
            {this.state.notifications.map((notification,i) => <div key={i} className={"alert alert-"+notification.type}>
                {notification.text}
            </div>)}
        </div>
    }



}
