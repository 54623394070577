export class DomUtil {


    static getOfClassOrParent(element,className): boolean {
        if (null == element || null == element.classList) {
            return null;
        }
        if (Array.isArray(className)) {
            for(const cn of className) {
                if (DomUtil.getOfClassOrParent(element, cn)) {
                    return element;
                }
            }
            return null;
        }
        if (element.classList.contains(className)) {
            return element;
        } else if (null != element.parentNode) {

            return DomUtil.getOfClassOrParent(element.parentNode,className);
        } else {
            return null;
        }
    }

    static getOfAttributeOrParent(element,attr,depth = 3) {
        const val = element.getAttribute(attr);
        if (undefined != val) {
            return element;
        } else {
            if (depth > 0 && undefined != element.parentNode) {
                return DomUtil.getOfAttributeOrParent(element.parentNode,attr,depth-1);
            } else {
                return null;
            }
        }
    }

    static contains(parent,element): boolean {
        return parent.contains(element);
    }

    static containsOrIs(parent,element): boolean {
        return parent === element || DomUtil.contains(parent,element)
    }

    static getRealScrollWidth(element) {
        const w: any = window;
        w["domut"] = DomUtil;
        const width = element.style.width;
        element.style.width = "1px";
        const result = element.scrollWidth;
        element.style.width = width;
        return result;
    }

    static getRealScrollHeight(element) {
        const w: any = window;
        w["domut"] = DomUtil;
        const height = element.style.height;
        element.style.height = "1px";
        const result = element.scrollHeight;
        element.style.height = height;
        return result;
    }
}