import React, {useState} from 'react';
import {ApiClient} from "../../lib/ApiClient";
import {LoadingComponent} from "../loading/LoadingComponent";
import {AppState} from "../../lib/AppState";


export interface SubscribeComponentProps {
    appState: AppState

}

export interface SubscribeComponentState {
    unsubscribeClick: number,
    reason: string
}

export class SubscibeComponent extends React.Component<SubscribeComponentProps, SubscribeComponentState> {

    componentDidMount() {

        this.setState({unsubscribeClick: 0, reason: ""});
    }


    getPlan() {
        // return this.props.appState.plans[0]
        return this.props.appState.plans[this.props.appState.plans.length-1]
    }

    public getPlanPrice() {
        return "" + new Number(this.getPlan().price / 100).toLocaleString("EN", {
            style: 'currency',
            currency: this.getPlan().currency,
            minimumFractionDigits: 2
        });
    }



    subscribe() {
        (async () => {
            console.log("form", this.props.appState.user.invoiceData);
            this.props.appState.setLoading(true);
            const saved: any = await this.props.appState.client.request("saveUserData",
                {form: this.props.appState.user.invoiceData});
            console.log("saved", saved);
            if ("ok" !== saved["status"]) {
                if (undefined !== saved["message"]) {

                    this.props.appState.error(saved["message"]);
                } else {

                    this.props.appState.error("An error occured with your invoice data, please check if they are correct");
                }
                this.props.appState.setLoading(false);
            } else {
                const url = await this.props.appState.client.request("getCheckoutUrl?redirectTo=" + window.location.href);
                if (undefined != url) {
                    const w: any = window;
                    w.location.href = url.url;
                } else {
                    this.props.appState.setLoading(false);
                    this.props.appState.error("An error occured");
                }
            }
        })();

    }

    private doUnsubscribe() {
        (async () => {

            (async () => {
                this.props.appState.setLoading(true);

                const status: any  = await this.props.appState.client.request("unsubscribe",{reason: this.state.reason});

                if ("ok" !== status["status"]) {
                    this.props.appState.error("An error occured");
                    window.location.reload();
                    this.props.appState.setLoading(false);
                } else {
                    this.props.appState.success("Your subscription has been canceled");
                    window.location.reload();

                }
            })();

        })();
    }

    public unsubscribe() {
        const state = this.state.unsubscribeClick;
        switch (state) {
            case 0:
                this.setState({unsubscribeClick: 1});
                break;
            case 1:
                this.doUnsubscribe();
                break;
        }
    }

    render() {
        if (null == this.state) {
            return <LoadingComponent/>;
        }
        return <div>{this.props.appState.user.subscribed ? <div>

            <div className="card bg-white border border-success mb-3">
                <div className="card-header">Subscribed, <b>thank you! 🤩</b>. You are helping to
                    make {this.props.appState.app.title} better!
                </div>
                <div className="card-body">
                    <h5 className="card-title">
                        {this.getPlan().title}

                    </h5>
                    <p className="card-text">
                        To update your invoice data fill the form on the left. It will apply to invoices issued in the
                        future.
                    </p>
                </div>
                {0 == this.state.unsubscribeClick ?
                    <button className={"btn btn-light"} onClick={e => this.unsubscribe()}>
                        Cancel your subscription</button> : <div className="card-body">
                        <p>🙁 I'm really sorry the service didn't fit your needs. Could you write down what
                            can I improve or what is missing?</p>
                        <textarea className="form-control"
                                  onChange={e => this.setState({reason: e.target.value})}
                        />
                        <button disabled={0 == this.state.reason.length} className={"btn btn-danger mt-3"}
                                onClick={e => this.unsubscribe()}>
                            Cancel your subscription
                        </button>

                    </div>}

            </div>


        </div> : <div>

            <div className="card bg-white border border-light mb-3">
                <div className="card-header">🙁 Not subscribed</div>
                <div className="card-body">
                    <h5 className="card-title">
                        {this.getPlan().title}, {this.getPlanPrice()}

                        {/*<span className={"pull-right"}>*/}
                        {/*    (3x ☕)*/}
                        {/*</span>*/}
                    </h5>
                    <p className="card-text">
                        Fill the invoice data on the left, and then you can subscribe to the service. In the next step
                        you will be redirected to


                        Stripe to finish the transaction.
                    </p>
                </div>

                <button className={"btn btn-danger"} onClick={e => this.subscribe()}>
                    Subscribe
                </button>
            </div>


        </div>}</div>

    }


}
