import React, {useState} from 'react';

import "./loader.css";
import {ApiClient} from "../../lib/ApiClient";
import {LoadingComponent} from "../loading/LoadingComponent";
import {InvoiceDataComponent} from "../invoice/InvoiceDataComponent";
import {SubscibeComponent} from "../subscribe/SubscibeComponent";
import {AppState} from "../../lib/AppState";
import {InvoiceListComponent} from "../invoice/InvoiceListComponent";
import {NotificationComponent} from "../notification/NotificationComponent";
import {CryptoComponent} from "../crypto/CryptoComponent";
import {PaymentMethodsComponent} from "../paymentMethods/PaymentMethodsComponent";


export interface LoaderComponentProps {

}

export interface LoaderComponentState {

    appState: AppState,
    tokenError: boolean
}

export class LoaderComponent extends React.Component<LoaderComponentProps, LoaderComponentState> {

    private _client: ApiClient;

    private _loaded = false;

    private replacementUri = "#💎";

    componentDidMount() {
        if (this._loaded) {
            return;
        }
        this._loaded = true;

        const w: any = window;
        w["app"] = this;
        let token = null;
        if (new URLSearchParams(window.location.search).has("$")) {
            token = "" + new URLSearchParams(window.location.search).get("$");
            sessionStorage.setItem("token",token);
            window.history.replaceState({}, null, window.location.href.replace(/\?\$=(.*?)($|&)/, this.replacementUri));
        } else {
            token = sessionStorage.getItem("token");
            if (null == token) {
                this.setState({tokenError: true});
                return;
            }
        }

        this._client = new ApiClient(token);
        const appState = new AppState(null, null, null, null, true);

        appState.setLoading = loading => {
            appState.loading = loading;
            this.setState({appState: appState});
        }
        this.setState({appState: appState},() => {
            (async () => {
                await this.loadUser(true);
                if (null != this.state.appState.app) {
                    document.title = this.state.appState.app.title + " - Yagular Payments";
                    document.querySelector("link[rel=icon]").setAttribute('href', this.state.appState.app.logo);
                }
            })();
        });

    }

    private async loadUser(sessionCheck = false) {
        const appState = this.state.appState;
        appState.setLoading(true);
        const data: any = await this._client.request("getUser");
        if (undefined !== data.status && "error" === data.status || undefined != data.error) {
            this.setState({tokenError: true});
            return;
        }
        appState.app = data["app"];
        appState.user = data["user"];
        appState.plans = data["plans"];
        appState.client = this._client;
        (window)["appState"] = appState;
        if (sessionCheck) {
            await this.sessionCheck();
        } else {
            appState.setLoading(false);
        }


    }

    private async sessionCheck() {
        const session = new URLSearchParams(window.location.search).get("session_id");
        if (null != session && session.length > 0) {
            // window.history.replaceState({}, null, window.location.href.replace(/\$session_id=(.*?)($|&)/, ""));
            window.history.replaceState({}, null, window.location.href.replace(/\?(.*?)$/, this.replacementUri));
            this.state.appState.setLoading(true);

            const data: any = await this._client.request("postSuccess?session_id=" + session);
            if ("ok" == data.status) {
                await this.loadUser(false);
                this.state.appState.setLoading(false);
                InvoiceListComponent.reload();
            } else {
                this.state.appState.error("An error occured with the payment");
                this.state.appState.setLoading(false);
            }

        } else {
            this.state.appState.setLoading(false);
        }
    }

    private goBack() {
        if (null != this.state.appState.app.backUrl) {
            window.location.href = this.state.appState.app.backUrl;
        } else {
            window.history.back();
        }
    }


    render() {

        if (null == this.state) {
            return <LoadingComponent/>;
        }

        if (this.state.tokenError) {
            return <div className="container mt-lg-5">
                <div className="alert alert-danger text-center">
                    Sorry, your token has expired, please try again
                </div>
            </div>
        }

        return <div className="container">

            {null != this.state && null != this.state.appState.user ? <div className="mt-5">
                <a onClick={_ => this.goBack()} className="go-back-button text-dark"><i
                    className="fa fa-arrow-left"/> back to {this.state.appState.app.title}</a>

                <h1 className="headline mb-5 mt-5 pb-2">
                    <img className="app-logo" src={this.state.appState.app.logo}/>
                    {this.state.appState.app.title} - {this.state.appState.user.email}
                </h1>

                {this.state.appState.isCrypto() ? <CryptoComponent appState={this.state.appState}/> :
                    <div className="row">

                        <div className="col-md-6">
                            <div className="mb-5 display-6">Invoice data</div>
                            <div className="pb-5">
                                <InvoiceDataComponent appState={this.state.appState}/>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <SubscibeComponent appState={this.state.appState}/>

                            <InvoiceListComponent appState={this.state.appState} />
                            <PaymentMethodsComponent appState={this.state.appState} />



                        </div>


                    </div>}


                <section className=" text-center mt-5 pb-2">


                    <a href={"mailto:"+this.state.appState.app.contact}>

                        {this.state.appState.app.contact}
                    </a>,
                    by <a href="https://yagular.com" target="_blank">Yagular</a>




                </section>
                <NotificationComponent appState={this.state.appState} />
                {this.state.appState.loading ? <LoadingComponent/> : null}
            </div> : <LoadingComponent/>}
        </div>

    }


}
