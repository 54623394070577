import React, {useState} from 'react';
import {ApiClient} from "../../lib/ApiClient";

import {AppState} from "../../lib/AppState";
import {LoadingComponent} from "../loading/LoadingComponent";
import {CryptoUtil} from "../../lib/CryptoUtil";

import "./crypto.css";

declare var Web3;

export interface CryptoComponentProps {
    appState: AppState


}
export interface CryptoComponentState {

}

export class CryptoComponent extends React.Component<CryptoComponentProps,CryptoComponentState> {

    private web3;

    private async getWeb3() {
        if (undefined !== this.web3) {
            return this.web3;
        }
        const w: any = window;
        if (w.ethereum) {
            this.web3 = new Web3(w.ethereum);
            try {
                await w.ethereum.enable();

            } catch (err) {
                throw new Error("User denied");
                // $('#status').html('User denied account access', err)
            }
        } else if (w.web3) {
            this.web3 = new Web3(w.web3.currentProvider);

        } else {
            throw new Error("No Metamask (or other Web3 Provider) installed");
        }
        return this.web3;
    }

    componentDidMount() {
        // (async () => {
        //     this.web3 = (window)["w3"] = await this.getWeb3();
        //     this.forceUpdate();
        // })();
    }


    public getPlanPrice() {
        // return "";
        switch(this.getPlan().currency) {
            case "milli":
                return this.getPlan().price / 1000;
            default:
                return "--";
        }

    }


    getPlan() {
        return this.props.appState.plans[this.props.appState.plans.length-1]
    }

    public subscribe() {

    }

    render() {

        // if (null == this.web3) {
        //     return <LoadingComponent />;
        // }

        return <section >
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        {this.props.appState.user.subscribed ? <div>
                            subscribed @todo
                        </div>:<div>
                            <div className="card bg-white border border-light mb-3">
                                <div className="card-header">🙁 Not subscribed</div>
                                <div className="card-body">
                                    <h5 className="card-title">
                                        {this.getPlan().title},

                                        <span className="crypto-price">

                                            <i className="fab fa-ethereum" />
                                            {this.getPlanPrice()} ETH
                                        </span>
                                    </h5>
                                    <p className="card-text">
                                        Subscribe to {this.props.appState.app.title}
                                        for a <b>year</b> using Metamask
                                    </p>
                                </div>

                                <button className={"btn btn-success"} onClick={e => this.subscribe()}>
                                    🤩 Subscribe
                                </button>
                            </div>
                        </div>}
                    </div>
                    <div className="col-6">
                        <div className="alert alert-info">
                            <h6>What is Metamask?</h6>
                        </div>
                    </div>
                </div>

            </div>

        </section>

    }



}
