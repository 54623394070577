import React, {useState} from 'react';
import {ApiClient} from "../../lib/ApiClient";
import {InvoiceDataForm} from "./InvoiceDataComponent";


import "./invoice-data-element.css"
import {DomUtil} from "../../lib/DomUtil";

export interface InvoiceDataElementComponentProps {
    form: InvoiceDataForm,
    name: string,
    title: string,
    options?: any[]
    required?: boolean

}
export interface InvoiceDataElementComponentState {
   value: any,
    filter: string,
    dropdownOpen: boolean,

}

export class InvoiceDataElementComponent extends React.Component<InvoiceDataElementComponentProps,InvoiceDataElementComponentState> {


    private _outsideClickListener;

    componentDidMount() {
        this.setState({value: this.props.form[this.props.name],filter: "",dropdownOpen: false});
    }

    onChange(e,name) {
        this.props.form[name] = e.target.value;
        this.setState({
            value: e.target.value,
        });

    }

    onFilter(e,name) {
        const text = e.target.value.trim().toLowerCase();
        this.setState({filter: text});
    }

    onSelect(option) {
        this.props.form[this.props.name] = option.value;
        this.setState({
            value: option.value,
            filter: "",
        });
        this.dropdownClose();
    }

    getOptionsFiltered() {
        return this.props.options.filter(el => -1 !== el.name.toLowerCase().indexOf(this.state.filter));
    }

    getSelectedOption() {
        return this.props.options.find(el => el.value == this.state.value);
    }

    getSelectedOptionTitle() {
        const selected = this.getSelectedOption()
        if (null != selected) {
            return selected.name;
        } else {
            return "Select country..";
        }
    }

    dropdownOpen(e) {
        this.setState({dropdownOpen: true},() => {
            setTimeout(() => {
                const el: any = document.querySelector(".dropdown-search");
                if (null != el) {
                    el.focus();
                }
                const el2 = document.querySelector(".dropdown-selected");
                if (null != el2) {
                    el2["scrollIntoViewIfNeeded"]();
                }
            },10);
        });
        this._outsideClickListener = event => {
            console.log("outside click",event,DomUtil.getOfClassOrParent(event.target,"dropdown-container"));
            if (null == DomUtil.getOfClassOrParent(event.target,"dropdown-container")) {
                this.dropdownClose();
            }
        };
        setTimeout(() => {
            document.body.addEventListener("click",this._outsideClickListener);
        },10);

    }

    dropdownClose() {
        this.setState({dropdownOpen: false});
        document.body.removeEventListener("click",this._outsideClickListener);
    }

    isRequired() {
        return true === this.props.required;
    }

    render() {
        if (null == this.state) {
            return <div/>;
        }
        return <div>
            <section className="pb-4">
                <div className=" ">
                    <section className="w-100 p-2 text-center ">

                            <div className={"form-outline "+(this.state.dropdownOpen ? "dropdown-container-open" : "")}>
                                {null == this.props.options ? <input value={this.state.value} required={this.isRequired()} onChange={e => this.onChange(e,this.props.name)} type="text" className="form-control" /> : <div className="form-control dropdown-container">

                                    {this.state.dropdownOpen ? <div>

                                        {/*<i className="fa fa-times" onClick={e => this.dropdownOpen(e)} />*/}
                                        <input className="dropdown-search form-control" value={this.state.filter} placeholder="Search..." onChange={e => this.onFilter(e,this.props.name)} />
                                        <div className="dropdown">
                                            <div className="dropdown-values">
                                                {this.getOptionsFiltered().map((option,i) => <div className={"dropdown-option "+(this.state.value == option.value ? "dropdown-selected":"")}

                                                                                                  key={i} onClick={e => this.onSelect(option)} >{option.name}</div>)}
                                            </div>

                                        </div>


                                    </div>: <input onChange={e=> {}} className="form-control dropdown-open" onClick={e => this.dropdownOpen(e)} type="text"  value={this.getSelectedOptionTitle()} />}



                                    {/*<select*/}
                                    {/*    className="form-control"*/}
                                    {/*    value={this.state.value} onChange={e => this.onChange(e,this.props.name)}*/}
                                    {/*>*/}
                                    {/*    {this.props.options.map((option,i) => <option key={i} value={option.value} >{option.name}</option>)}*/}
                                    {/*</select>*/}
                                </div>}

                                    <label className="form-label"  >{this.props.title}</label>
                                    <div className="form-notch">
                                        <div className="form-notch-leading" />
                                        <div className="form-notch-middle" />
                                        <div className="form-notch-trailing" />
                                    </div>
                                </div>

                    </section>


                </div>
            </section>
        </div>

    }



}
