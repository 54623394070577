import React, {useState} from 'react';




export interface LoadingComponentProps {

}
export interface LoadingComponentState {

}

export class LoadingComponent extends React.Component<LoadingComponentProps,LoadingComponentState> {



    render() {
        return <div className="loading">
            <div className="spinner-grow"/>
            <div className="m-2 loading-info">Please wait..</div>
        </div>

    }



}
