import React, {useState} from 'react';
import {ApiClient} from "../../lib/ApiClient";
import {InvoiceDataElementComponent} from "./InvoiceDataElementComponent";
import {AppState} from "../../lib/AppState";


export interface InvoiceListComponentProps {
    appState: AppState


}
export interface InvoiceListComponentState {
    invoices: any[]
}


export class InvoiceListComponent extends React.Component<InvoiceListComponentProps,InvoiceListComponentState> {

    public static reload;

    componentDidMount() {
        InvoiceListComponent.reload = () => this.loadInvoices();
        this.loadInvoices();
    }

    private loadInvoices() {
        (async () => {
            const invoices: any = await this.props.appState.client.request("getInvoices");
            this.setState({
                invoices: invoices
            })
        })();
    }

    private _download(invoice) {
        (async () => {
            this.props.appState.setLoading(true);
          const resp: any = await this.props.appState.client.request("printInvoice?id="+invoice.id);
            this.props.appState.setLoading(false);
          if ("ok" === resp.status) {
              const a = document.createElement("a");
              a.download = ""+invoice.number+".pdf";
              a.href="data:application/pdf;base64,"+resp.data;
              a.target="_blank";
              document.body.appendChild(a);
              a.click();
              setTimeout(() => a.remove(),10000);
          }
        })();
    }

    render() {
        if (null == this.state || undefined === this.state.invoices) {
            return <div/>;
        }
        if (0 == this.state.invoices.length) {
            return <div className="alert alert-info">No invoices found</div>
        }
        return <section className="mt-5">
            <h5>Invoices</h5>
            <ul className="list-group list-group-light list-group-small" >
                {this.state.invoices.map((el,i) =>
                    <li key={i} className="list-group-item ">
                        <span className="text-muted fa-pull-right ">{el.date}</span>
                        <a href="#" onClick={_ => this._download(el)}>

                            {el.number}</a>

                    </li>
                )}

            </ul>
        </section>

    }



}
