import {ApiClient} from "./ApiClient";

export class AppState {


    constructor(public app,
                public user,
                public plans,
                public client: ApiClient,
                public loading: boolean = false

    ) {
    }

    public error(msg) {
        alert(msg);
    }

    public success(msg) {
        alert(msg);
    }

    public setLoading: (loading: boolean) => void = null;

    public isCrypto() {
        return -1 !== ["milli","gwei","eth"].indexOf(this.plans[0].currency);
    }
}

