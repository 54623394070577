import React from 'react';
import logo from './logo.svg';
import './App.css';
import {LoaderComponent} from "./components/loader/LoaderComponent";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://7d74147c36ef4ad39e3cbe7aa6ca89f8@o80432.ingest.sentry.io/6405940",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

function App() {
  return (
    <LoaderComponent />
  );
}

export default App;
