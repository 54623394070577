import React, {useState} from 'react';
import {ApiClient} from "../../lib/ApiClient";

import {AppState} from "../../lib/AppState";
import {LoaderComponent} from "../loader/LoaderComponent";
import {LoadingComponent} from "../loading/LoadingComponent";


export interface PaymentMethodsComponentProps {
    appState: AppState


}
export interface PaymentMethodsComponentState {
    methods: any[]
}


export class PaymentMethodsComponent extends React.Component<PaymentMethodsComponentProps, PaymentMethodsComponentState> {


    componentDidMount() {

        this.loadMethods();
    }

    private loadMethods() {
        (async () => {
            const methods: any = await this.props.appState.client.request("getPaymentMethods");
            console.log("methods",methods);
            this.setState({
                methods: methods.paymentMethods,

            });
            this.props.appState.setLoading(false);
        })();
    }

    private canDelete() {
        return this.state.methods.length > 1 || !this.props.appState.user.subscribed;
    }

    private delete(el) {
        const c: any = window["confirm"];
        if (!c("Are you sure you want to delete this payment method?")) {
            return;
        }
        (async () => {
            this.props.appState.setLoading(true);
            console.log("delete card",el);
            await this.props.appState.client.request("deletePaymentMethod", {
                id: el.id
            });
            this.loadMethods();

        })();

    }

    private add() {
        (async () => {
            this.props.appState.setLoading(true);
            const url = await this.props.appState.client.request("getSetupUrl?redirectTo=" + window.location.href);

            if (undefined != url) {
                const w: any = window;
                w.location.href = url.url;
            } else {
                this.props.appState.setLoading(false);
                this.props.appState.error("An error occured");
            }
        })();
    }

    render() {
        if (null == this.state || undefined === this.state.methods) {
            return <div/>;
        }

        if (0 == this.state.methods.length) {
            return <div style={{marginTop: "20px"}} className="alert alert-info">No payment methods found</div>
        }
        return <section className="mt-5">


            <h5>Payment Methods
            </h5>
            <ul className="list-group list-group-light list-group-small" >
                {this.state.methods.map((el,i) =>
                    <li key={i} className="list-group-item ">
                        <span style={{"float": "right"}}>
                        <span className={"text-muted"}>
                             {el.card.exp_month}/{el.card.exp_year}
                        </span>
                            {this.canDelete() ?
                                <a  className="text-danger " style={{marginLeft: "10px",cursor:"pointer"}}
                                    onClick={_ => this.delete(el)}>
                                    <i className="fa fa-trash"/>
                                </a>
                                : null}

                        </span>

                        {el.card.brand.toUpperCase()} **** **** **** {el.card.last4}



                    </li>
                )}

            </ul>
            <a onClick={() => this.add()} className="text-primary" style={{float: "right",marginTop: "10px",cursor:"pointer"}}><i className="fa fa-plus"></i> Add</a>
        </section>

    }



}
